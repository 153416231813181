<template>
  <div
    class="attribute-pill mr-1 mb-1"
    :style="{ backgroundColor: item.color }"
  >
    {{ item.name }}
  </div>
</template>

<script>
export default {
  name: 'BasePill',
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.attribute-pill {
  padding: 5px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 500;
}
</style>
